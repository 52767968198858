@import url('https://fonts.googleapis.com/css2?family=Headland+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.headlandfont {
    font-family: "Headland One", serif;
    font-weight: 400;
    font-style: normal;
}

.bg-orange {
    background-color: #ec5f1a;

}

.text-orange {
    color: #ec5f1a;
}

.tablebox {
    z-index: 888;
    position: relative;
}



.body-text {
    color: #575864;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.dashcontent h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
}

.icondash i {
    font-size: 21px;
    color: white;

    position: absolute;

}

.icondash1 i {

    left: 38px;

    top: 39px;
}



.icondash2 i {

    left: 40px;

    top: 40px;
}

.icondash3 i {
    left: 35px;
    top: 42px;
}

.icondash4 i {
    left: 35px;
    top: 41px;
}



.boxclip {
    width: 280px;
    height: 300px;
    perspective: 900px;
}

.clipcard {
    height: 100%;
    width: 100%;
    background-color: aliceblue;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;
    border-radius: 2rem;
}

.boxclip:hover>.clipcard {
    cursor: pointer;
    transform: rotateY(180deg) rotateZ(180deg);
}

.front,
.back {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    position: absolute;
    box-shadow: 0 0 10px 2px rgba(50, 50, 50, 2.5);
    backface-visibility: hidden;
    color: aliceblue;
    background: linear-gradient(-135deg,
            #00b9ff, #ec5f1a);
}

.front,
.back {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.back {
    transform: rotateY(180deg) rotateZ(180deg);
}

.back-heading,
.front-heading {
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}


/* product component */

.card {
    --accent-color: #ec5f1a;
    position: relative;
    width: 100%;
    background: white;
    height: 100%;
    border-radius: 1rem;
    padding: 0.3rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 50px 30px -20px;
    transition: all 0.5s ease-in-out;
}

.card .image-container {
    position: relative;
    width: 100%;
    height: 230px;
    border-radius: 0.7rem;
    border-top-right-radius: 4rem;
    margin-bottom: 1rem;
}

.card .image-container img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: inherit;
}

.card .image-container .svg {
    height: 100%;
    width: 100%;
    border-radius: inherit;
}

.card .image-container .price {
    position: absolute;
    right: 0.7rem;
    bottom: -1rem;
    background: white;
    color: var(--accent-color);
    font-weight: 900;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 1rem 1rem 2rem 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
}

.card .favorite {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 10px;
    right: 5px;
    cursor: pointer;
}

.card .favorite input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.card .favorite input:checked~svg {
    animation: bouncing 0.5s;
    fill: rgb(255, 95, 95);
    filter: drop-shadow(0px 3px 1px rgba(53, 53, 53, 0.14));
}

.card .favorite svg {
    fill: #a8a8a8;
}

.card .content {
    padding: 0px 0.8rem;
    margin-bottom: 1rem;
}

.card .content .brand {
    font-weight: 900;
    color: #ec5f1a;
}

.card .content .product-name {
    font-weight: 700;
    color: #666666;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.card .content .product-maintitle {
    font-size: 22px;
}

.card .content .color-size-container {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
    color: #a8a8a8;
    gap: 2rem;
    margin-bottom: 1.5rem;
}

.card .content .color-size-container>* {
    flex: 1;
}

.card .content .color-size-container .colors .colors-container {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
    font-size: 0.5rem;
    margin-top: 0.2rem;
}

.card .content .color-size-container .colors .colors-container .color {
    height: 14px;
    position: relative;
}

.card .content .color-size-container .colors .colors-container .color:hover .color-name {
    display: block;
}

.card .content .color-size-container .colors .colors-container .color a {
    display: inline-block;
    height: 100%;
    aspect-ratio: 1;
    border: 3px solid black;
    border-radius: 50%;
}

.card .content .color-size-container .colors .colors-container .color .color-name {
    display: none;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    background: black;
    padding: 0.2rem 1rem;
    border-radius: 1rem;
    text-align: center;
}

.card .content .color-size-container .colors .colors-container .color:first-child a {
    border-color: #ec5f1a;
}

.card .content .color-size-container .colors .colors-container .color:nth-child(2) a {
    background: #144076;
}

.card .content .color-size-container .colors .colors-container .color:nth-child(3) a {
    border-color: #00b9ff;
}

.card .content .color-size-container .colors .colors-container .color:nth-child(4) a {
    border-color: #ff6ba1;
}

.card .content .color-size-container .colors .colors-container .active {
    border-color: black;
}

.card .content .color-size-container .sizes .size-container {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.card .content .color-size-container .sizes .size-container .size {
    height: 14px;
}

.card .content .color-size-container .sizes .size-container .size .size-radio {
    cursor: pointer;
}

.card .content .color-size-container .sizes .size-container .size .size-radio input {
    display: none;
}

.card .content .color-size-container .sizes .size-container .size .size-radio input:checked~.name {
    background: var(--accent-color);
    border-radius: 2rem 2rem 1.5rem 1.5rem;
    color: white;
}

.card .content .color-size-container .sizes .size-container .size .size-radio .name {
    display: grid;
    place-content: center;
    height: 100%;
    aspect-ratio: 1.2/1;
    text-decoration: none;
    color: #484848;
    font-size: 0.5rem;
    text-align: center;
}

.card .content .rating {
    color: #a8a8a8;
    font-size: 0.6rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.card .content .rating svg {
    height: 12px;
}

.card .button-container {
    display: flex;
    gap: 0.3rem;
}

.card .button-container .button {
    border-radius: 1.4rem 1.4rem 0.7rem 0.7rem;
    border: none;
    padding: 0.5rem 0;
    background: var(--accent-color);
    color: white;
    font-weight: 900;
    cursor: pointer;
}

.card .button-container .button:hover {
    background: orangered;
}

.card .button-container .buy-button {
    flex: auto;
}

.card .button-container .cart-button {
    display: grid;
    place-content: center;
    width: 50px;
}

.card .button-container .cart-button svg {
    width: 15px;
    fill: white;
}

.card:hover {
    transform: scale(1.03);
}

@keyframes bouncing {

    from,
    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(1.5, 2.1);
    }

    50% {
        transform: scale(2.1, 1.5);
    }

    75% {
        transform: scale(1.5, 2.05);
    }
}


/* tooltip */



.uiverse {
    position: relative;
    background: #ffffff;
    color: #000;

    margin: 0px;
    border-radius: 10px;

    font-size: 12px;
    font-size: 17px;
    padding: 6px 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.tooltipname {
    font-size: 12px;
}


.tooltip1 {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip1::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse:hover .tooltip1 {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

svg:hover span,
svg:hover .tooltip1 {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.uiverse:hover,
.uiverse:hover .tooltip1,
.uiverse:hover .tooltip1::before {
    background: linear-gradient(320deg, #ec5f1a, #ec5f1a);
    color: #ffffff;
}

/* checkbox */

.checkbox-wrapper-19 {
    box-sizing: border-box;
    --background-color: #fff;
    --checkbox-height: 25px;
}

@-moz-keyframes dothabottomcheck-19 {
    0% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) / 2);
    }
}

@-webkit-keyframes dothabottomcheck-19 {
    0% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) / 2);
    }
}

@keyframes dothabottomcheck-19 {
    0% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) / 2);
    }
}

@keyframes dothatopcheck-19 {
    0% {
        height: 0;
    }

    50% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) * 1.2);
    }
}

@-webkit-keyframes dothatopcheck-19 {
    0% {
        height: 0;
    }

    50% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) * 1.2);
    }
}

@-moz-keyframes dothatopcheck-19 {
    0% {
        height: 0;
    }

    50% {
        height: 0;
    }

    100% {
        height: calc(var(--checkbox-height) * 1.2);
    }
}

.checkbox-wrapper-19 input[type=checkbox] {
    display: none;
}

.checkbox-wrapper-19 .check-box {
    height: var(--checkbox-height);
    width: var(--checkbox-height);
    background-color: white;
    border: calc(var(--checkbox-height) * .1) solid #000;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease 0.2s;
    -o-transition: border-color ease 0.2s;
    -webkit-transition: border-color ease 0.2s;
    transition: border-color ease 0.2s;
    cursor: pointer;
}

.checkbox-wrapper-19 .check-box::before,
.checkbox-wrapper-19 .check-box::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    height: 0;
    width: calc(var(--checkbox-height) * .2);
    background-color: #34b93d;
    display: inline-block;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-radius: 5px;
    content: " ";
    -webkit-transition: opacity ease 0.5;
    -moz-transition: opacity ease 0.5;
    transition: opacity ease 0.5;
}

.checkbox-wrapper-19 .check-box::before {
    top: calc(var(--checkbox-height) * .72);
    left: calc(var(--checkbox-height) * .41);
    box-shadow: 0 0 0 calc(var(--checkbox-height) * .05) var(--background-color);
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.checkbox-wrapper-19 .check-box::after {
    top: calc(var(--checkbox-height) * .37);
    left: calc(var(--checkbox-height) * .05);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.checkbox-wrapper-19 input[type=checkbox]:checked+.check-box,
.checkbox-wrapper-19 .check-box.checked {
    border-color: #34b93d;
}

.checkbox-wrapper-19 input[type=checkbox]:checked+.check-box::after,
.checkbox-wrapper-19 .check-box.checked::after {
    height: calc(var(--checkbox-height) / 2);
    -moz-animation: dothabottomcheck-19 0.2s ease 0s forwards;
    -o-animation: dothabottomcheck-19 0.2s ease 0s forwards;
    -webkit-animation: dothabottomcheck-19 0.2s ease 0s forwards;
    animation: dothabottomcheck-19 0.2s ease 0s forwards;
}

.checkbox-wrapper-19 input[type=checkbox]:checked+.check-box::before,
.checkbox-wrapper-19 .check-box.checked::before {
    height: calc(var(--checkbox-height) * 1.2);
    -moz-animation: dothatopcheck-19 0.4s ease 0s forwards;
    -o-animation: dothatopcheck-19 0.4s ease 0s forwards;
    -webkit-animation: dothatopcheck-19 0.4s ease 0s forwards;
    animation: dothatopcheck-19 0.4s ease 0s forwards;
}

/* tablebox */




.tablebox .cutombtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    margin-top: 5px;
    border: none;
    border: 1px solid #ed9b7366;
    background: #ed9b7366;
    border-radius: 6px;
    cursor: pointer;
}



.tablebox .cutombtn:hover {
    background: #ec5f1a;
    color: white;
}

.tablebox .cutombtn:hover i {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-8deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.progressline a,
.progressline span {
    line-height: 38px !important;
}

/* Horizontal layout for larger screens */
.stepper-container {
    display: flex;
    justify-content: center;
}

.stepper-container .stepper-wrapper {
    display: flex;
    flex-direction: row;
}

/* Vertical layout for smaller screens */
@media (max-width: 768px) {
    .stepper-container .stepper-wrapper {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .stepper-container .step {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .stepper-container .circle {
        margin-right: 10px;
    }

    .stepper-container .step .title {
        margin-top: 0;
    }

    .stepper-container .step .description {
        margin-top: 0;
    }
}

.product-name {
    margin-bottom: 5px;
    font-size: 14px;
}


.bg-dark {
    background-color: black !important;
}



/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(182, 182, 182);
    transition: .4s;
    border-radius: 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 8px;
    left: 0.3em;
    bottom: 0.3em;
    transform: rotate(270deg);
    background-color: rgb(255, 255, 255);
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #21cc4c;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.switch input:checked+.slider:before {
    transform: translateX(1.5em);
}

.storebox h5,
.storebox span,
.storebox h6 {
    font-weight: 600;
}

.storeboxnotfound img {
    height: 300px;
    object-fit: cover;
}

.tabletooltip .uiverse {
    position: relative;
    background: transparent !important;
    color: #000;
    margin: 0px;
    border-radius: 10px;
    font-size: 17px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tabletooltip .uiverse:hover .tooltip1 {
    top: 0px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    right: -90px;
    z-index: 1000000000;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabletooltip .tooltip1::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: 0px;
    left: 0%;
    top: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    position: inherit;
    z-index: 99999;
}

.MuiDataGrid-row {
    z-index: 9984;
}

.css-14mxsc7-MuiDataGrid-root .MuiDataGrid-cell {
    overflow: visible;
}



/* .css-14mxsc7-MuiDataGrid-root .MuiDataGrid-cell {
    overflow: visible;
}

.MuiDataGrid-columnHeaderTitleContainer {
    overflow: visible;
} */


.tablebox .MuiBox-root {
    height: 500px;
    overflow: auto;
}