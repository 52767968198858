.searchbar .InputContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
}

.searchbar input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9em;
    caret-color: rgb(255, 81, 0);
}

.searchbar .labelforsearch {
    cursor: text;
    padding: 0px 12px;
}

.searchbar .searchIcon {
    width: 13px;
}

.searchbar .border {
    height: 40%;
    width: 1.3px;
    background-color: rgb(223, 223, 223);
}

.searchbar .micIcon {
    width: 12px;
}

.searchbar .micButton {
    padding: 0px 15px 0px 12px;
    border: none;
    background-color: transparent;
    height: 40px;
    cursor: pointer;
    transition-duration: .3s;
}

.searchbar .searchIcon path {
    fill: rgb(114, 114, 114);
}

.searchbar .micIcon path {
    fill: rgb(255, 81, 0);
}

.searchbar .micButton:hover {
    background-color: rgb(255, 230, 230);
    transition-duration: .3s;
}