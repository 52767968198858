body {
    background-color: #fff4f0;
}

.loginbox {
    max-width: 450px;
    width: 100%;
    background: #F8F9FD;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
    border-radius: 40px;
    padding: 25px 35px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
    margin: 20px;
}

.heading {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: #EA561D;
}

.form {
    margin-top: 20px;
}

.form-group label {
    font-weight: 600;
    font-size: 16px;

}

.form .input {
    width: 100%;
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    /* margin-bottom: 15px; */
    box-shadow: #cff0ff 0px 10px 10px -5px;
    border-inline: 2px solid transparent;
}

.form .input::-moz-placeholder {
    color: rgb(170, 170, 170);
}

.form .input::placeholder {
    color: rgb(170, 170, 170);
}

.form .input:focus {
    outline: none;
    border-inline: 2px solid #EA561D;
}

.form .forgot-password {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
}

.form .forgot-password a {
    font-size: 11px;
    color: #EA561D;
    text-decoration: none;
}

.form .login-button {
    display: block;
    width: 100%;
    font-weight: bold;
    background: linear-gradient(45deg, #EA561D 0%, #EA561D 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
    transform: scale(1.03);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
    margin-top: 25px;
}

.social-account-container .title {
    display: block;
    text-align: center;
    font-size: 10px;
    color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
    background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
    border: 5px solid white;
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
    transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
    fill: white;
    margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
    transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
    transform: scale(0.9);
}

.agreement {
    display: block;
    text-align: center;
    margin-top: 15px;
}

.agreement a {
    text-decoration: none;
    color: #EA561D;
    font-size: 9px;
}

/* login.css */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.OtpBox input {
    height: 3rem !important;
    width: 3rem !important;
    border: 1px solid grey;
    border-radius: 10px;
}




/* LOGIN ANIMATION */


.splash-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fa9a74;
    display: flex;
    justify-content: center;
    align-items: center;
}

.splash-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.splash-content .logo {
    width: 88px;
    height: 88px;
}

.animated-circle {
    position: absolute;
    border-radius: 50%;
    background-color: #EA561D;
    /* appOrangeColor */

    transition: all 0.7s ease-in-out;
}

/* Existing styles */








.opacity-100 {
    opacity: 1;
}

.opacity-0 {
    opacity: 0;
}

.transition-opacity {
    transition: opacity 1s ease-in-out;
}

.duration-1000 {
    transition-duration: 1s;
}

.ease-in-out {
    transition-timing-function: ease-in-out;
}

.loginbox {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.transition-transform {
    transition: transform 0.3s ease-in-out;
}

.duration-300 {
    transition-duration: 0.3s;
}

.ease-in-out {
    transition-timing-function: ease-in-out;
}

.transform {
    transform: scale(1);
}

.heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.w-24 {
    width: 6rem;
}

.h-auto {
    height: auto;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
}

/* .focus\:outline-none {
    outline: none;
}

.focus\:ring-2 {
    box-shadow: 0 0 0 0.25rem #EA561D;
}

.focus\:ring-blue-500 {
    box-shadow: 0 0 0 0.25rem #EA561D;
} */

.login-button {
    width: 100%;
    padding: 0.5rem;
    background-color: #EA561D;
    color: white;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.login-button:hover {
    transform: scale(1.05);
}

.OtpBox {
    display: flex;
    flex-direction: column;

}